import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';


const Clue6 = () => (
  <Layout>
    <Header /> 

    <header className="masthead7">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #6</h1>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br> 
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            From ship to shore, 
            <br></br>
            I am easy to see.
            <br></br>
            <br></br>
            Fishing and surfing,
            <br></br>
            it's the place to be.
            <br></br>
            <br></br>
            While storms may come
            <br></br>
            and they may go.
            <br></br>
            <br></br>
            Count on tides 
            <br></br>
            both high and low.
            <br></br>
            <br></br>
          </h2>
          <br></br>
          <br></br>

            <a href="/hint6" className="btn btn-primary">
              Hint
            </a>
          <br></br>
          <br></br>
          <br></br>
          
        </div>
      </div>
    </header>
    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue6;
